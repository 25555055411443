body {

  background-image: url('./assets/fundo-side-menu.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.white-text {
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.product-card {
  background-color: #18303c !important;
  color: #ffffff !important;
  border: 2px solid #28a745 !important; /* Correct green color */
  margin: 0px;
  padding: 0px;
  border-radius: 0 !important;
}

.product-badge {
  background-color: #0ee684; /* Correct green color */
  color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: px;
  font-weight: bold;
}

.justify-button-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

/* Store.css */

.toggle-btn-group {
  width: 100%;
}

.toggle-btn {
  background-color: transparent !important;
  color: lightgray !important;
  border-color: #5079A2 !important;
  /* width: 20%; */
  flex-grow: 1;
}

.toggle-btn.active {
  
  /* background-color: #5079A2 !important; Zima Blue */
  background-color: #0ee684 !important; /* Zima Blue */
  color: rgb(7, 86, 105) !important;
  border-color: #5079A2 !important;
  
}